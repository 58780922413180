

import config from '@/js/config.js'
import router from '@/router'

import {mapGetters,mapActions} from 'vuex'


router.beforeEach((to, from, next) => {
    next();
    //gtag('config', GTAG_ID,{'page_path': to.path});// for google analytics to send data whiel routing to each page
    
    })

export default{
    data() {
        return {
        }
    },
  
      
      beforeMount() {
        window.addEventListener('resize', this.resizeHandler)

      },
      computed:{
        ...mapGetters([              
            'isMobile',
       ]),
      },
      mounted() {
        this.resizeHandler();//initital to check whether device is in which mobile or desktop mode
      },
      methods: {
        ...mapActions([
            "deviceChangeSizeEvent",
        ]),

        isMobileChecking(e) {
            let deviceWidth = 0;
            if (typeof e === "undefined") {
                deviceWidth = window.innerWidth;
            } else {
                deviceWidth = e.currentTarget.innerWidth 
            }
       
            if (deviceWidth > 768) {           
                return false
            } else{
                return true
            }
        },
        resizeHandler(e) { 
         
            const isMobile = this.isMobileChecking(e);
            this.deviceChangeSizeEvent(isMobile);
        },

  


      },
}