const TOKEN = "token"
const user = {
    state: {
        token: '' ,
        isLogin: false,
        name:''
    },
    getters:{
        isLogin:state => state.isLogin,
    },
    mutations: {
        SET_LOGIN:(state,data) => {
            state.isLogin = data;
        },

        LOGIN:(state,data) => {
          state.name = data.name;
          localStorage.setItem(TOKEN, new Date().toDateString());
          state.isLogin = true
        },

        LOGOUT:(state) => {
            localStorage.removeItem(TOKEN);
            state.isLogin = false

        }
    },
    actions: {
        setLogin({commit},params){
            commit("SET_LOGIN",params);
        },
        login({commit},params){
            commit("LOGIN",params);
        },
        logout({commit},params){
            commit("LOGOUT",params);
        },
    },
}
export default user