import { createRouter, createWebHistory } from "vue-router";
import PortfolioDetail from "@/views/PortfolioDetail.vue";
import config from "@/js/config.js"
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },  
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: () => import('../views/Portfolio.vue'),
  },
  {
    path: '/portfolio/:id',
    name: 'PortfolioDetail',
    component: () => import('../views/PortfolioDetail.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 60);
    }
  }
});

for(let i = 0; i < config.projectList.length; i++) {
  let project = config.projectList[i];
  router.addRoute({ path: `/${project.urlName}`, meta:{ id: project.urlName}, component: PortfolioDetail })
}
export default router;
