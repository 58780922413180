import { createStore } from "vuex";
import user from './modules/user'
export default createStore({
  modules: {
    user
  },
  state: {
    isMobile: false,
    isLoading: true
  },
  mutations: {
    DEVICE_CHANGE_SIZE_EVENT:(state,data) => {
        state.isMobile = data 
    },
    LOADING_EVENT (state, data) {
              
      state.isLoading = data;
      document.getElementsByTagName('body')[0].style.overflow = "auto"
  },
  },
  getters:{
    isLoading:state =>state.isLoading,
    isMobile:state =>state.isMobile,
  },
  actions: {
    loadingEvent({commit},params){
      commit("LOADING_EVENT",params);
    },
    deviceChangeSizeEvent({commit},params){
      commit("DEVICE_CHANGE_SIZE_EVENT",params);
    },
  },

});
