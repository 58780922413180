<template>
  <div v-if="isShow" class="modal-wrapper">   
      <div class="modal-container" :class="{'show': isShowContent}">
        <div class="modal-close" @click="handleClose()"><img src="@/assets/img/close_btn.png"></div>
          <slot name="body"></slot>
          <!-- <div class="modal-header"></div>
          <div class="modal-body"></div>
          <div></div> -->
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            isShow: false,
            isShowContent: false
    
        }
    },
    methods: {
        handleClose() {
            this.isShowContent = false;
            setTimeout(() => {
                this.isShow = false;
            }, 500);
        },
        handleOpen() {
            this.isShow= true;
            setTimeout(() => {
                this.isShowContent = true;
            }, 500);
            
        }
    },
}
</script>

<style>
.modal-wrapper{
    background-color: #0000009e;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
}
.modal-container{
    background-color: #fff;
    border-radius: 2rem;
    border: 0.2rem solid #000;
    box-shadow: 5px 5px #000;
    padding: 3.5rem 2.5rem 2.5rem 2.5rem;
    position: relative;
    width: 70%;
    transition: all .3s;
     transform: scale(0);
     overflow: hidden;
}
.modal-container.show{
    transform: scale(1);
}
.modal-close{
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    position: absolute;
    right: 0.7rem;
    top: 0.7rem;
}
.modal-close img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
@media (max-width: 768px) {
    .modal-swiper-container{
        height: auto;
    }
    .modal-close{
        width: 2rem;
        height: 2rem;
    }
    .modal-swiper-container{
        height: auto !important;
    }
    .modal-container{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}
</style>