<template>
        <div class="header-wrapper">
            <div class="header-container container">
                <router-link :to="{ name: 'Home' }" class="header-logo">
                    <img class="w-100" src="@/assets/img/logo.png">
                </router-link>
                <ul class="header-menu">
                    <router-link :to="{ name: o.routeName }" v-for="(o, index) in headerList" :key="index">{{ o.displayName }}</router-link>
                </ul>
                <div class="mobile-menu__burger-icon__wrapper " :class="{'open': isMenuOpen}" @click="handleMenu()">
                    <div id="nav-icon3" class="mobile-menu__burger-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="header-divider"></div>
         <div class="mobile-menu__list-wrapper" :class="{'open': isMenuOpen}">
            <ul class="mobile-menu__list-container header-menu">
                <router-link :to="{ name: o.routeName }" v-for="(o, index) in headerList" :key="index" @click="handleMenu()">{{ o.displayName }}</router-link>
            </ul> 
        </div>
</template>

<script>

export default {
    data() {
        return {
            isMenuOpen: false,

            headerList: [
                {
                    routeName: 'Home',
                    displayName: 'Home'
                },
                {
                    routeName: 'About',
                    displayName: 'About'
                },
                {
                    routeName: 'Portfolio',
                    displayName: 'Portfolio'
                },
                {
                    routeName: 'Contact',
                    displayName: 'Contact'
                },
            ]
        }

    },
    mounted() {
         
    },
    methods: {
        handleMenu() {
           this.isMenuOpen = !this.isMenuOpen;
           if (this.isMenuOpen) {
                document.body.style.overflow = 'hidden'
           } else {
                document.body.style.overflow = 'auto'
           }
        }
    },
}
</script>

<style>
    .header-wrapper{
        background-color: white;
        padding: 1rem  0;
    }
    .header-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .header-logo{
        width: 163px;
    }
    .header-menu{
        display: flex;
        grid-column-gap: 3.5rem;
    }
    .header-menu li{
        color: #0B2149;
        font-size: 1rem;
        font-family: 'Arial';
        border-bottom: .25rem;
    }
    .header-divider{
        width: 100%;
        height: 1rem;
        background-color: var(--color-theme);
    }
    .header-menu a{
        text-decoration: none;
        color: var(--color-blue);
        padding-bottom: 3px;
    }
    .header-menu .router-link-active{
        font-weight: bold;
        border-bottom: 3px solid var(--color-theme);
    }
    /* mobile menu */
    .mobile-menu__burger-icon__wrapper{
        display: none;
    }
    .mobile-menu__list-wrapper{
        display: none;
    }
    @media (max-width: 768px) {
        .header-menu{
            display: none;
        }
        .header-container{
            justify-content: center;
        }

        .header-divider{
            height: .5rem;
        }
        .header-menu a{
            margin-bottom: 1.5rem;
        }  
        .mobile-menu__burger-icon__wrapper{
            position: absolute;
            right: 1.5rem;
            display: block;
        }
        .mobile-menu__list-wrapper{
            display: block;
        }
        .mobile-menu__burger-icon{
            width: 2rem;
            height: 1.5rem;
            position: relative;
            -webkit-transform: rotate( 0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .5s ease-in-out;
            -moz-transition: .5s ease-in-out;
            -o-transition: .5s ease-in-out;
            transition: .5s ease-in-out;
            cursor: pointer;
        }
        .mobile-menu__burger-icon span{
            display: block;
            position: absolute;
            height: .25rem;
            width: 100%;
            background: var(--color-blue);
            border-radius: 9px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);

            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);

            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
        }
        .mobile-menu__burger-icon span:nth-child(1){  
            top: 0px;
        }
        .mobile-menu__burger-icon span:nth-child(2){  
            top: .5rem;
        }
        .mobile-menu__burger-icon span:nth-child(3){  
            top: .5rem;
        }
        .mobile-menu__burger-icon span:nth-child(4){  
            top: 1rem;
        }

        .open .mobile-menu__burger-icon span:nth-child(1){  
            top: .5rem;
            width: 0%;
            left: 50%;
        }
        .open .mobile-menu__burger-icon span:nth-child(2){  
        -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }
        .open .mobile-menu__burger-icon span:nth-child(3){  
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
        .open .mobile-menu__burger-icon span:nth-child(4){  
            top: .5rem;
            width: 0%;
            left: 50%;
        }
        .mobile-menu__list-wrapper{
            position: fixed;
            background-color: #fff;
            left: 0;
            right: 0;
            bottom: 0;
            top: 5.6rem;
            transition: all .3s;
            opacity: 0;
            pointer-events: none;
            z-index: 99;
            overflow-y: auto;
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
            width: 100vw;
        }
        .mobile-menu__list-wrapper.open{
            opacity: 1;
            pointer-events: fill;
        }
        .mobile-menu__list-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-left: 0;
        }
        .mobile-menu__list-container li{
            margin-top: 2.5rem;
            position: relative;
            font-size: 1.2rem;
        }
    }
</style>