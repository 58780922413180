<template>
        <div class="footer-banner__wrapper">
            <div class="relative">
                <div class="footer-banner__container">
                    <h1 class="footer-banner__title">Get in touch and find out how we can help</h1>
                    <router-link :to="{name: 'Contact'}" class="footer-banner__btn">Contact Us</router-link>   
                </div>
                <img class="h-100 w-100" src="@/assets/img/footer_banner.png">
            </div>
        </div>
        <div class="footer-info__wrapper container">
            <div class="footer-info__left">
                <h1>DESIGN & 
                    BUILD</h1>
            </div>
           
            <div class="footer-info__right">
                <div class="footer-info__container ">
                    <div class="footer-info__right-title">LOCATION</div>
                    <p class="footer-info__right-address">
                        A-31-G, Jalan Bayu Laut 15B/KS09, 
                        Kota Bayu Emas, Pendamar, 
                        41200 Klang, Selangor Darul Ehsan, 
                        Malaysia
                    </p>
                    <p v-if="!isMobile">Copyright ©{{ currentYear }} All rights reserved.</p>
                </div>
                <div class="footer-info__container ">
                    <div class="footer-info__right-title">Contact Info</div>
                    <div>
                        <div class="footer-info__contact-container">
                            <img class="footer-info__contact-icon" src="@/assets/img/icon_phone.png">
                            <div>+603 3101 4800</div>
                        </div>
                        <div class="footer-info__contact-container">
                            <img class="footer-info__contact-icon" src="@/assets/img/icon_email.png">
                            <div>ask.sxdb@shuixingventures.com</div>
                        </div>
                        <div class="footer-info__contact-container">
                            <img class="footer-info__contact-icon" src="@/assets/img/icon_web.png">
                            <div>www.sxdesignandbuild.com</div>
                        </div>
                    </div>
                </div>
                <p v-if="isMobile" class="footer-info__copyright">Copyright ©{{ currentYear }} All rights reserved.</p>
            </div>
     
        </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    data() {
        return {
            currentYear: 2023
        }
    },
    computed:{
		...mapGetters([              
			'isMobile',
		]),
	},
    mounted() {
        this.currentYear = new Date().getFullYear();
         window.sr = ScrollReveal();
	    this.$nextTick(() => {
      		sr.reveal('.scroll-from-left', {
				origin: 'left' ,
				duration: 1000,
				easing: 'ease-out' ,
				distance: '500px',
				delay: 100,
				         
			});
			    sr.reveal('.scroll-from-right', {
                    origin: 'right' ,
                    duration: 1000,
                    easing: 'ease-out' ,
                    distance: '200px',
                    delay: 100     ,
					     
                });
                sr.reveal('.scroll-from-bottom', {    
                    duration: 1000,
                    distance: '50px',
                    delay: 100              
                });
                sr.reveal('.scroll-from-top', {    
                    origin: 'top' ,
                    duration: 1000,
                    distance: '200px',
                    delay: 100              
                });
				  sr.reveal('.scroll-from-opacity', {    
                       distance: '200px',
						opacity: 0.8,
						duration: 1000,
                });
				 sr.reveal('.scroll-from-scaleUp', {    
                       distance: '200px',
   				scale: 0.85,
                duration: 1000,
                });
      	    });
    },
}
</script>

<style>

.footer-banner__wrapper{
    background-color: var(--color-blue);
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 4rem;
}
.footer-banner__container{
    position: absolute;
    width: 44.1875rem;
    left: 2rem;
    top: 4rem;
}
.footer-banner__title{
    font-size: 3.125rem;
    color: var(--color-blue);
}
.footer-banner__btn{
    padding: 0.7rem 1.5rem;
    border: .1rem solid var(--color-blue);
    cursor: pointer;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--color-blue);
    transition: all .3s;
}
.footer-banner__btn:hover{
    background-color: var(--color-blue);
    color: white;
}
.btn{
    overflow: hidden;
    position: relative;
}
.btn::before, .btn::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.footer-banner__btn::before {
    content: '';
    background: blue;
    width: 120%;
    left: -10%;
  
    transform: translate3d(100%,0,0);
    transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}
.footer-banner__btn:hover::before {
    transform: skew(30deg);
}

.footer-info__wrapper{
    display: flex;
    justify-content: space-between;
    padding-bottom: 3rem;
    box-sizing: border-box;
}
.footer-info__right{
    display: flex;
}
.footer-info__container{
    width: 265px;
    color: var(--color-blue);
}
.footer-info__left{
    width: 176px;
    color: var(--color-blue);
}
.footer-info__left h1{
    font-size: 2.125rem;
}
.footer-info__right{
    column-gap: 8.75rem;
}
.footer-info__right-title{
    font-weight: bold;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
}
.footer-info__right-address{
    line-height: 1.5rem;
    margin-bottom: 3rem;
}
.footer-info__contact-container{
    display: flex;
    margin-bottom: .5rem;
}
.footer-info__contact-icon{
    margin-right: .5rem;
}


@media (max-width: 768px) {
    .footer-banner__container{
        width: 80%;
        top: 1rem;
    }
    .footer-banner__title{
        font-size: 1.25rem;
    }
    .footer-info__wrapper{
        flex-direction: column;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1.5rem;
    }
    .footer-info__right{
        flex-direction: column;
    }
    .footer-banner__btn{
        font-size: .75rem;
    }
    .footer-banner__wrapper{
        margin-bottom: 1rem;
        height: 10rem;
    }
    .footer-info__left h1{
        font-size: 1.75rem;
        margin-bottom: 4rem;
    }
    .about-client__wrapper{
        margin-top: 20rem;
        padding: 2rem 0;
    }
    .footer-info__copyright{
         color: var(--color-blue);
         font-size: 0.625rem;
         margin-top: 2rem;
    }
    .footer-info__contact-container{
        font-size: .75rem;
        align-items: center;
    }
}
</style>