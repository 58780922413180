module.exports = {
    mobileWidth: 765,

    projectList: [
        {
          urlName: 'ShuiXingVenturesSdnBhd',
       
          info: {
            name: 'Shui Xing Ventures Sdn Bhd',
            co: 'Malaysia',
            project: '1 Unit, 260,000 sqf Single Storey Warehouse, 2 Storey Office Block & Other Anicillary Bldgs.',
            contractPeriod: '12 Months',
            location: 'Pasir Gudang,Johor',
            workScope: 'Main Contractor',
            completion: 'Year 2020',
            coverImg: 'ShuiXingVentures/1.jpg',
            galleryName: 'ShuiXingVentures',
            gallery: [
              '1.jpg',
              '2.jpg',
              '3.jpg',
            ],
            video:[
              '1.mp4'
            ]
          }
        },
        {
          urlName: 'BrightSeriesSdnBhd',

          info: {
            name: 'Bright Series Sdn Bhd',
            co: 'Vietnam',
            project: 'Multi type single story warehouses, totalled of 1.2mil sqf warehouse space',
            contractPeriod: '18 Months',
            location: 'PKFZ, Port Klang',
            workScope: 'Main Contractor',
            completion: 'Year 2021',
            coverImg: 'BrightSeries/1.jpg',
            galleryName: 'BrightSeries',
            gallery: [
              '1.jpg',
              '2.jpg',
              '3.jpg',
            ],
          },
        },
        {
            urlName: 'HouseOfLove',
  
            info: {
              name: 'House Of Love (SX CSR Project)',
              co: 'Malaysia',
              project: 'Lot 94347, Jalan Lebuh Bagor/KS, Taman Petaling, Mukim Klang, Klang Bandar Diraja,Selangor.',
              contractPeriod: '12 Months',
              location: 'Klang Selangor',
              workScope: 'Main Contractor',
              completion: 'Year 2022',
              coverImg: 'HouseOfLove/1.jpg',
              galleryName: 'HouseOfLove',
              gallery: [
                '1.jpg',
                '2.jpg',
                '3.jpg',
              ],
            },
          },
          {
            urlName: 'SXServiceTransport',
  
            info: {
              name: 'SX Service & Transport Sdn. Bhd',
              co: 'Malaysia',
              project: 'Refurbishment of Burned Warehouse, 80,000 sqf with 4 storey office',
              contractPeriod: '9 Months',
              location: 'Northport, Port Klang',
              workScope: 'Main Contractor',
              completion: 'Year 2020',
              coverImg: 'SXServiceTransport/1.jpg',
              galleryName: 'SXServiceTransport',
              gallery: [
                '1.jpg',
                '2.jpg',
                '3.jpg',
              ],
            },
          }
      ]
  
}