<template>
    <div class="portfolio-banner">
        <div class="portfolio-banner__cover"></div>
        <img class="" :src="require(`@/assets/img/portfolio/${info.coverImg}`)">
        <h1 class="portfolio-title">{{ info.name }}</h1>
    </div>

    <div class="container">
       <div class="portfolio-gallery__wrapper">
            <div class="swiper mySwiper portfolio-gallery__swiper">
                <div class="swiper-wrapper ">
                    <div class="swiper-slide" v-for="(o, index) in info.gallery" :key="index" @click="handleGallery(o,index)">
                        <img class="portfolio-gallery__img" :src="require(`@/assets/img/portfolio/${info.galleryName}/${o}`)">
                    </div>
                </div>
            
            </div>
                <div class="swiper-button-next ">
                    <img src="@/assets/img/swiper_right.png">
                </div>
                <div class="swiper-button-prev ">
                    <img src="@/assets/img/swiper_left.png">
                </div>
            <div class="swiper-pagination"></div>
        </div> 
        <div class="portfolio-detail__wrapper has-space">
            <div class="portfolio-detail__header">
                <h2 class="portfolio-detail__header-title">{{ info.name }}</h2>
                <div class="portfolio-detail__header-subtitle">co: <span>{{ info.co }}</span></div>
            </div>
            <div class="portfolio-detail__body">
                <div class="portfolio-detail__rows">
                    <div class="portfolio-detail__card">
                        <div class="portfolio-detail__label">Project</div>
                        <div class="portfolio-detail__info">{{ info.project }}</div>
                    </div>
                    <div class="portfolio-detail__card">
                        <div class="portfolio-detail__label">Contract Period</div>
                        <div class="portfolio-detail__info">{{ info.contractPeriod }}</div>
                    </div>
                </div>
                 <div class="portfolio-detail__rows">
                    <div class="portfolio-detail__card">
                        <div class="portfolio-detail__label">Location</div>
                        <div class="portfolio-detail__info">{{ info.location }}</div>
                    </div>
                    <div class="portfolio-detail__card">
                        <div class="portfolio-detail__label">Work Scope</div>
                        <div class="portfolio-detail__info">{{ info.workScope }}</div>
                    </div>
                </div>
                <div class="portfolio-detail__rows">
                    <div class="portfolio-detail__card">
                        <div class="portfolio-detail__label">Completion</div>
                        <div class="portfolio-detail__info">{{ info.completion }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="portfolio-video__wrapper has-space" v-if="info.video">
            <video class="portfolio-video w-100" :src="require(`@/assets/img/portfolio/${info.galleryName}/1.mp4`)" controls></video>
        </div>
    </div>
    <ModalPopup ref="modalPopupRef">
         <template v-slot:body>
             <div class="modal-swiper">
                <div class="mySwiper modal-swiper-container"  id="modalSwiper">
                    <div class="swiper-wrapper ">
                        <div class="swiper-slide" v-for="(o, index) in info.gallery" :key="index" @click="handleGallery(o)">
                            <img class="modal-swiper-img" :src="require(`@/assets/img/portfolio/${info.galleryName}/${o}`)">
                        </div>
                    </div>
                </div>
                    <div class="swiper-button-next modal-swiper-next">
                        <img src="@/assets/img/swiper_right.png">
                    </div>
                    <div class="swiper-button-prev modal-swiper-prev">
                        <img src="@/assets/img/swiper_left.png">
                    </div>
                <div class="swiper-pagination modal-swiper-pagination"></div>
            </div>
        </template>
    </ModalPopup>
</template>

<script>
import config from '@/js/config.js'
import ModalPopup from '@/components/ModalPopup'

export default {    
    components: {
        ModalPopup
    },
    data() {
        return {
            info: {
                coverImg: 'ShuiXingVentures/1.jpg',
                galleryName: 'ShuiXingVentures'
            }
        }
    },
    mounted() {
        this.getPortfolioDetail();
        this.$nextTick(() => {
            this.bannerInit();
        });
          
    },
    methods: {
        bannerInit() {
             var swiper = new Swiper(".mySwiper", {
                slidesPerView: 3,
                spaceBetween: 10,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            });  
            
         
        },

        getPortfolioDetail() {
            let routeParamsId = this.$route.meta.id;
     
            let projectList = config.projectList;

            let selectedProject = projectList.find(x => x.urlName === routeParamsId);
 
            this.info = selectedProject.info
        },

        handleGallery(o, index) {
            
            this.$refs.modalPopupRef.handleOpen();

            this.$nextTick(() => {
                var swiper1 = new Swiper("#modalSwiper", {
                    navigation: {
                        nextEl: ".modal-swiper-next",
                        prevEl: ".modal-swiper-prev",
                    },
                    pagination: {
                        el: ".modal-swiper-pagination",
                        clickable: true,
                    },
                });
                swiper1.activeIndex = index
            })
        }
    },
}
</script>

<style>
.portfolio-banner{
    height: 35rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.portfolio-banner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.portfolio-title{
    position: absolute;
    color: white;
    font-weight: bold;
    font-size: 3.5rem;
}
.portfolio-banner__cover{
    background-color: #0000003d;
    position: absolute;
    width: 100%;
    height: 100%;
}
.portfolio-gallery__img{
    height: 12.875rem;
    width: 20.3125rem;
    border-radius: 1rem;
}
.portfolio-gallery__wrapper{
    margin-top: 1rem;
    position: relative;
    margin-bottom: 9rem;
}

/* detail */
.portfolio-detail__header{
    display: flex;
    align-items: center;
    border-bottom: 2px solid #969FB1;
    color: var(--color-theme-2);
    margin-bottom: 3rem;
}
.portfolio-detail__header-title{
    font-size: 2.125rem;
    margin-right: 2rem;
}
.portfolio-detail__header-subtitle{
    font-size: 1.5rem;
}
.portfolio-detail__rows{
    display: flex;
}
.portfolio-detail__label{
    color: #6B7280;
    margin-bottom: .25rem;
}
.portfolio-detail__card{
    margin-right: 6.125rem;
    margin-bottom: 2.25rem;
    width: 26.5rem;
}
.portfolio-detail__wrapper{
    margin-bottom: 3rem;
}
/* video */
.portfolio-video{
    border-radius: 1rem;
}
.portfolio-video__wrapper{
    margin-bottom: 5rem;
}

/* swiper */
.swiper-button-prev:after{
    content: '';
}
.swiper-button-next:after{
    content: '';
}
.swiper-button-prev{
    left: 20px;
}
.swiper-button-next{
    right: 20px;
}
.portfolio-gallery__swiper{
    width: 1000px;
}
.swiper-pagination{
    bottom: -26px !important;
}
.swiper-pagination-bullet{
    background: rgb(233,111,30,0.7);
}
.swiper-pagination-bullet-active{
    background: rgb(233,111,30);
}
.portfolio-gallery__imgs{
    width: 100%;
}

.modal-swiper{
    position: relative;
}
.modal-swiper-container{
    overflow: hidden;
    height: 66vh;
}
.modal-swiper-img{
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    object-fit: contain;
}
.swiper-button-next img{
    /* width: 3rem; */
}
.swiper-button-prev img{
    /* width: 3rem; */
}
/* .modal-swiper-next{
    right: -33px;
}
.modal-swiper-prev{
    left: -33px;
} */
@media (max-width: 768px) {
    .portfolio-title{
        font-size: 1.125rem;
    }
    .portfolio-banner{
        height: 40vw;
    }
    .portfolio-gallery__img{
        width: 100%;
        height: auto;
        border-radius: .25rem;
    }
    .portfolio-gallery__swiper{
        width: auto;
        padding: 0 1rem;
    }
    .portfolio-gallery__wrapper{
        margin-bottom: 2rem;
    }
    .portfolio-detail__header{
        flex-direction: column;
        align-items: flex-start;
    }
    .portfolio-detail__header-title{
        font-size: 1.5rem;
        margin-bottom: .5rem;
        margin-right: 0;
    }
    .portfolio-detail__header-subtitle{
        font-size: .9rem;
        margin-bottom: 1rem;
    }
    .portfolio-detail__rows{
        flex-direction: column;
    }
    .portfolio-detail__card{
        width: 100%;
    }
    .swiper-button-prev{
        display: none;
    }
    .swiper-button-next{
        display: none;
    }

    
}
</style>