<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
     <Header></Header>
       <router-view/>
     <Footer></Footer>
  <div></div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

 import ResizeMixin from '@/js/handleResizeAndRouting.js'
import '@/assets/css/common.css';
export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
   mixins: [ResizeMixin],
  
}
</script>

<style>

</style>
